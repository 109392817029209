
import { useTranslation } from 'i18next-vue';
import { isPlainObject } from 'lodash';
import { nextTick, toValue } from 'vue';

// Helper method for writing translation to innerHTML
function writeT (el, binding, vnode, t) {
    const { value } = binding;
    let key;
    let options = {};

    if (isPlainObject(value)) {
        ({ key } = value);
        options = { ...value };
        delete options.key;
    }
    else {
        key = value;
    }

    if (!key) {
        console.warn('v-t: "key" is required', { el, binding, vnode });
        return;
    }

    el.innerHTML = t(key, options);
}

const rerenderOn = ['languageChanged', 'loaded', 'added', 'removed'];
const elsHandlers = new WeakMap();

// Composable
export function useI18next (namespaces, options = {}) {
    const { t, i18next } = useTranslation(
        toValue(namespaces),
        toValue(options),
    );

    // Directive
    const vT = {
        created: (el, binding, vnode) => {
            const handler = () => nextTick(() => {
                writeT(el, binding, vnode, t);
            });
            elsHandlers.set(el, handler);

            // Taken from https://github.com/i18next/i18next-vue/blob/vue-2/index.ts#L44
            rerenderOn.forEach(event => {
                switch (event) {
                    case 'added':
                    case 'removed':
                        i18next.store?.on(event, handler);
                        break;
                    default:
                        i18next.on(event, handler);
                        break;
                }
            });
        },

        mounted: (el, binding, vnode) => {
            writeT(el, binding, vnode, t);
        },

        updated: (el, binding, vnode) => {
            writeT(el, binding, vnode, t);
        },

        unmounted: (el, binding, vnode) => {
            const handler = elsHandlers.get(el);

            if (!handler) {
                return;
            }

            rerenderOn.forEach(event => {
                switch (event) {
                    case 'added':
                    case 'removed':
                        i18next.store?.off(event, handler);
                        break;
                    default:
                        i18next.off(event, handler);
                        break;
                }
            });
        },
    };

    return { i18next, t, vT };
}
